import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewClass, getAllPresenters, getBatchFaculty } from '../../actions/Classes';
import swal from 'sweetalert';
import moment from 'moment';
class AddClass extends React.Component {
  constructor(props){
    super(props);
    const {params} = props.match;
    this.state = { batch_id : params.batch_id, startDate: '', endDate: '', presenterData: {}};
  }

  componentWillMount() {
    console.log("testing", this.state.batch_id );
    this.props.onGetAllPresenters( this.state.batch_id );
  }

  onChangeHandler = (type, value) => {
   
    if(type == 1) {
      var startDateTime = moment(value).format('YYYY-MM-DD HH:mm:ss');
      this.setState({startDate: startDateTime})
    } else if(type == 2) {
      var endDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
      this.setState({endDate: endDate})
    } else if(type == 3) {
      this.setState({presenterData: this.props.presenters[value]})
    }
  }

  onAddClassSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new class?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_class_form'));
        var class_levels = document.getElementsByClassName('class_levels');
        var levels = new Array();

        for(let i=0;i<class_levels.length;i++)
        {
          if(class_levels[i].checked)
          {
            levels.push(class_levels[i].value);
          }
        }

        formData.append('class_levels', levels.toString());
        this.props.onAddNewClass(formData);
      }
    });
  }

  render() {

    const {batch_id, startDate, endDate, presenterData} = this.state;
    const {presenters} = this.props;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/batches">Batches</Link> </li>
                  <li className="breadcrumb-item"><Link to={"/classes/" + batch_id}>Classes</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Class</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Class </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
            </div>
          </div>
          
          {/*  Add new class form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 card p-3">
              <br/>

              <form id="add_class_form" onSubmit={this.onAddClassSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Class TItle * : </label>
                      <input type="text" name="class_title" placeholder="Class Title *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Start Date and Time * : </label>
                      <input type="datetime-local" placeholder="Start Date *" className="form-control" onChange={(e) => this.onChangeHandler(1, e.target.value)} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> End Date * : </label>
                      <input type="datetime-local" placeholder="End Date *" className="form-control" onChange={(e) => this.onChangeHandler(2, e.target.value)} required/>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text"> Presenter * : </label>
                      <select name="faculty_id" className="form-control" onChange={(e) => this.onChangeHandler(3, e.target.value)} required>
                        <option value=""> Select Presenter </option>

                        {
                          presenters && presenters.length > 0 && 
                            presenters.map((presenter, index) => (
                              <option key={index} value={presenter.id}>{presenter.faculty_name}</option>
                            ))
                        }
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Duration in Minutes (Only numbers) * : </label>
                      <input type="tel" name="duration" placeholder="Class Duration" className="form-control" required/>
                    </div>

                  <div className="form-group">
                      <label className="small_text"> Class Repeat Type * : </label>
                      <select name="class_repeat_type" className="form-control" required>
                        <option value=""> Select Class Repeat Type </option>
                        <option value="1"> Daily (All 7 Days) </option>
                        <option value="2"> Monday to Saturday </option>
                        <option value="3"> Monday to Friday </option>
                        <option value="4"> Weekly Once </option>
                        <option value="5"> Monthly Once </option>
                      </select>
                    </div>

                    <input type="hidden" name="batch_id" value={batch_id} />
                    <input type="hidden" name="start_time" value={startDate} />
                    <input type="hidden" name="end_time" value={endDate} />
                    <input type="hidden" name="presenter_id" value={presenterData ? presenterData.teacher_id : 0} />
                    <input type="hidden" name="presenter_name" value={presenterData ? presenterData.name : ''} />
                    <input type="hidden" name="presenter_email" value={presenterData ? presenterData.email : ''} />
                    <input type="hidden" name="status" value="1" />



                  </div>
                </div>
              
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Class </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading,
    presenters: state.classe.presenters,
  }
};

const mapActionsRoProps = {
  onGetAllPresenters: getBatchFaculty,
  onAddNewClass: addNewClass,
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddClass);
