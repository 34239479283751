import React from 'react';
import './Reports.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { onDisplayError, onLoadingTrue, onLoadingFalse } from '../../actions/Login';
import swal from 'sweetalert';
import server from '../../utils/Server';

class Reports extends React.Component {

	constructor (props) {
		super(props);
		this.state = {message: ''};
	}

	handleDownloadReport = (e) => {
		e.preventDefault();

		swal({
			title: "Are you sure?",
			text: "To download selected report?",
			buttons: ['Cancel', 'Download'],
		})
		.then(willDownload => {
			if (willDownload) {
				this.props.onLoadingTrue();
				var report_type = document.getElementById('report_type').value;

				var link = document.createElement('a');
				link.href = 'https://api.classicedu.org/public/api/ReportDownload?report_type=' + report_type;
				link.target = "_blank";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				this.props.onLoadingFalse();
			}
		});
	} 

	// handleDownloadReport = (e) => {
	// 	e.preventDefault();

	// 	swal({
	// 		title: "Are you sure?",
	// 		text: "To download selected report?",
	// 		buttons: ['Cancel', 'Download'],
	// 	})
	// 	.then(willDownload => {
	// 		if (willDownload) {
	// 			this.props.onLoadingTrue();
	// 			var report_type = document.getElementById('report_type');

	// 			server.get('/ReportDownload?report_type=' + report_type)
	// 				.then(response => {
	// 					this.props.onLoadingFalse();
	// 					if(response.data.statuscode === 200) {
		
	// 						// // Create anchor tag and download file
	// 						// const link = document.createElement('a');
	// 						// link.href = 'https://api.classicedu.org/public/' + response.data.file;
	// 						// link.download = response.data.file;
	// 						// document.body.appendChild(link);
	// 						// link.click();
	// 						// document.body.removeChild(link);
		
	// 						// setTimeout( this.props.onDisplayMessage(response.data.message), 5000);

	// 						this.props.onDisplayError(response.data.error);

	// 					} else {
	// 						this.props.onDisplayError(response.data.error);
	// 					}

	// 					console.log('Success', response);
	// 				})
	// 				.catch(error => {
	// 					this.props.onLoadingFalse();
	// 					this.props.onDisplayError(error.message);
	// 				});
	// 		}
	// 	});
	// } 

  	render() {
		const {message} = this.state;

    	return (
			<div className="row">
				<div className="col">
		
					{/* Breadcrumbs and add button container */}
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<nav area-label="breadcrumb">
								<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Reports</li>
								</ol>
							</nav>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<h4 className="text-center page_header"> REPORTS </h4>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
						</div>
					</div>

					{/* Edit admin profile */}
					<div className="row add_container form_container">
						<div className="col"></div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
							<h5 className="text-center page_header text-uppercase mt-2">DOWNLOAD REPORT</h5>

							<form id="send_report_form" onSubmit={this.handleDownloadReport} autoComplete="off">
								<div className="form-group">
									<label className="small_text"> Report type * : </label>
									<select id="report_type" className="form-control" required>
										<option value=""> Select report type</option>
										<option value="1">All Users Report</option>
										<option value="2">Student Report</option>
										<option value="3">Course Report</option>
										<option value="4">Class Report</option>
										<option value="5">Exam Report</option>
										<option value="6">Notification Report</option>
										<option value="7">Achiever Report</option>
										<option value="8">Books Report</option>
										<option value="9">Finance Report</option>
									</select>
								</div>
								
								<div className="form-group text-center">
									<button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > DOWNLOAD </button>
								</div>
							</form>
						</div>
						<div className="col"></div>
					</div>

				</div>
			</div>
		);
  	}

}

const mapStatesToProps = (state) => {
	return {
		isLoading: state.admin.isLoading,
	}
}

const mapActionsToProps = {
	onDisplayError: onDisplayError,
	onLoadingTrue: onLoadingTrue,
	onLoadingFalse: onLoadingFalse,
}

export default connect(mapStatesToProps, mapActionsToProps) (Reports);
