import server from '../utils/Server';
import { onGetFacultiesSuccess } from './Faculties';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';
import { onGetUserSuccess } from './Users';

export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';

export const getAllHostelBookings = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllUserHostelBookings')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetBookingsSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                    // dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getUserBookings = (mobileNumber, params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetUserBookings/' + mobileNumber + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetBookingSuccess(response.data.data));
                    dispatch(onGetUserSuccess(response.data.userData));
                }
                else{
                    dispatch(onDisplayError(response.data.message))
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'))
            });
    }
}

export const addNewHostel = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewHostel', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getHostelDetails = (hostel_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditHostel/' + hostel_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetBookingSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateHostel = (hostel_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/UpdateHostel/' + hostel_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteHostel = (hostel_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteHostel/' + hostel_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllHostelBookings());
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const onGetBookingsSuccess = (bookings) => {
    return {
        type : GET_BOOKINGS_SUCCESS,
        payload : {
            bookings : bookings
        }
    }
}

export const onGetBookingSuccess = (booking) => {
    return {
        type : GET_BOOKING_SUCCESS,
        payload : {
            booking : booking
        }
    }
}
