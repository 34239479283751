import server from '../utils/Server';
import { onGetFacultiesSuccess } from './Faculties';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';

export const getAllCourses = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllCourses')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetCoursesSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                    // dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getCoursesWithBatches = (type) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetCourseWithBatches/' + type)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetCoursesSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message))
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'))
            });
    }
}

export const getDropDownCourses = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetDropDownCourses')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetCoursesSuccess(response.data.courses));
                    dispatch(onGetFacultiesSuccess(response.data.faculties));
                }
                else{
                    dispatch(onDisplayError(response.data.message))
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'))
            });
    }
}

export const addNewCourse = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewCourse', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getCourseDetails = (course_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditCourse/' + course_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetCourseSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateCourse = (course_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/UpdateCourse/' + course_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteCourse = (course_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteCourse/' + course_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllCourses());
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const onGetCoursesSuccess = (courses) => {
    return {
        type : GET_COURSES_SUCCESS,
        payload : {
            courses : courses
        }
    }
}

export const onGetCourseSuccess = (course) => {
    return {
        type : GET_COURSE_SUCCESS,
        payload : {
            course : course
        }
    }
}
