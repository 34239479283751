import { GET_WRITTEN_EXAMS_SUCCESS, GET_WRITTEN_EXAM_SUCCESS } from '../actions/WrittenExam';

const initialState = {
    writtenExams: [],
    writtenExam: {},
}

export default function Class_reducer (state = initialState, { type, payload } ) {
    switch (type)
    {
        case GET_WRITTEN_EXAMS_SUCCESS :
            return Object.assign({}, state, { writtenExams : payload.writtenExams });

    case GET_WRITTEN_EXAM_SUCCESS :
            return Object.assign({}, state, { writtenExam : payload.writtenExam });



        default :
            return state;
    }

}
