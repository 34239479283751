import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_FACULTIES_SUCCESS = 'GET_FACULTIES_SUCCESS';
export const GET_FACULTY_SUCCESS = 'GET_FACULTY_SUCCESS';

export const getAllFaculties = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllFaculties' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetFacultiesSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getActiveFaculties = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetFaculty')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetFacultiesSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewFaculty = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewFaculty', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const getFacultyDetails = (faculty_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditFaculty/' + faculty_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetFacultySuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateFaculty = (faculty_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/UpdateFaculty/' + faculty_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteFaculty = (faculty_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteFaculty/' + faculty_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllFaculties(''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};


export const onGetFacultiesSuccess = (data) => {
    return {
        type : GET_FACULTIES_SUCCESS,
        payload : {
            faculties : data
        }
    }
}

export const onGetFacultySuccess = (data) => {
    return {
        type : GET_FACULTY_SUCCESS,
        payload : {
            faculty : data
        }
    }
}