import server from '../utils/Server';
import { onGetFacultiesSuccess } from './Faculties';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_HOSTELS_SUCCESS = 'GET_HOSTELS_SUCCESS';
export const GET_HOSTEL_SUCCESS = 'GET_HOSTEL_SUCCESS';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';

export const getAllHostels = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllHostels')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetHostelsSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                    // dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getDropDownHostels = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetHostels')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetHostelsSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message))
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'))
            });
    }
}

export const addNewHostel = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AddNewHostel', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getHostelDetails = (hostel_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditHostel/' + hostel_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetHostelSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateHostel = (hostel_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/UpdateHostel/' + hostel_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteHostel = (hostel_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteHostel/' + hostel_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllHostels());
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const onGetHostelsSuccess = (hostels) => {
    return {
        type : GET_HOSTELS_SUCCESS,
        payload : {
            hostels : hostels
        }
    }
}

export const onGetHostelSuccess = (hostel) => {
    return {
        type : GET_HOSTEL_SUCCESS,
        payload : {
            hostel : hostel
        }
    }
}
