import React from 'react';
import './Books.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewBook } from '../../actions/Books';
import swal from 'sweetalert';

class Books extends React.Component {
  constructor(props){
    super(props);
    this.state = { bookType: 0, images : { book_image_path : ''} };
    this.onAddBookSubmit = this.onAddBookSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (e) => {
    let images = this.state.images;
    images[e.target.name] = URL.createObjectURL(e.target.files[0]);
    this.setState({ images : images });
  }

  onAddBookSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new book?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_book_form'));
        this.props.onAddNewBook(formData);
      }
    });
  }

  render() {

    const { images, bookType } = this.state;
    let image_preview = '';

    if(images.book_image_path){
      image_preview = <img src={images.book_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/books">Books</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Book</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Book </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                {/*  */}
            </div>
          </div>
          
          {/*  Add new book form  */}
          <div className="row add_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-12 card p-3">
              <br/>

              <form id="add_book_form" onSubmit={this.onAddBookSubmit} autoComplete="off">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <input type="text" name="book_name" placeholder="Book Name *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <select name="book_type" className="form-control" required>
                        <option value="0"> E-Book </option>
                        <option value="1"> Publications </option>
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                      <label className="small_text">Book Image * : </label>
                        <input type="file" name="book_image_path" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                          {image_preview}
                      </div>
                    </div>

                    <div className="form-group">
                      <select name="payment_type" onChange={(e) => this.setState({bookType: e.target.value})} className="form-control" required>
                        <option value="0"> Free </option>
                        <option value="1"> Paid </option>
                      </select>
                    </div>

                    <div className="form-group">
                      {
                        bookType == "1" ? 
                          <input type="number" name="book_price" className="form-control" placeholder="Book Price in rupees" required />
                        : <input type="hidden" name="book_price" className="form-control" value={0} placeholder="Book Price in rupees" />
                      }
                      
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Upload Book * : </label>
                      <input type="file" name="book_file_path" placeholder="Book Video Path *"  className="form-control" required />
                    </div>

                    <div className="form-group">
                        <textarea rows={5} name="book_discription" placeholder="Book Description *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <select name="status" className="form-control" required>
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>
               
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Book </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading
  }
};

const mapActionsRoProps = {
  onAddNewBook : addNewBook
};

export default connect(mapStatesToProps, mapActionsRoProps) (Books);
