import server from '../utils/Server';
import { onGetFacultiesSuccess } from './Faculties';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS';
export const GET_CLASS_SUCCESS = 'GET_CLASS_SUCCESS';
export const GET_PRESENTER_SUCCESS = 'GET_PRESENTER_SUCCESS';

export const getAllClasses = (facultyID) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetFacultyClasses/' + facultyID)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetClassesSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                    // dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getAllPresenters = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetPresenter')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onGetPresentersSuccess(response.data.data));
                }
                else {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}


export const getBatchFaculty = (batchID) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getBatchFaculty/'+batchID)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onGetPresentersSuccess(response.data.data));
                }
                else {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewClass = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        //ScheduleDailyClass
        return server.post('/ScheduleOnlineClass', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getClassDetails = (class_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditClass/' + class_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetClassSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateClass = (class_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/UpdateClass/' + class_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteClass = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/CancelClass', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllClasses());
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const onGetClassesSuccess = (data) => {
    return {
        type : GET_CLASSES_SUCCESS,
        payload : {
            classes : data
        }
    }
}

export const onGetClassSuccess = (classe) => {
    return {
        type : GET_CLASS_SUCCESS,
        payload : {
            class : classe
        }
    }
}

export const onGetPresentersSuccess = (presenters) => {
    return {
        type : GET_PRESENTER_SUCCESS,
        payload : {
            presenters : presenters
        }
    }
}
