import React from 'react';
import './Routes.css';
import { Route, NavLink, HashRouter as Router } from 'react-router-dom';
import $ from 'jquery';

import Dashboard from '../Dashboard';

import Users from '../Users';
import AddUser from '../Users/AddUser';
import EditUser from '../Users/EditUser';

import Admissions from '../Admissions';
import AddAdmission from '../Admissions/AddAdmission';
import EditAdmission from '../Admissions/EditAdmission';

import HostelBookings from '../HostelBookings';
import AddHostelBooking from '../HostelBookings/AddBooking';
import EditHostelBooking from '../HostelBookings/EditBooking';

import Hostels from '../Hostels';
import AddHostel from '../Hostels/AddHostel';
import EditHostel from '../Hostels/EditHostel';

import Notifications from '../Notifications';

import Reports from '../Reports';

import AdminUsers from '../AdminUsers';
import AddAdmin from '../AdminUsers/AddAdmin';
import EditAdmin from '../AdminUsers/EditAdmin';

import NewsUpdates from '../NewsUpdates';
import AddNews from '../NewsUpdates/AddNews';
import EditNews from '../NewsUpdates/EditNews';

import RollingImages from '../RollingImages';
import AddImage from '../RollingImages/AddImage';
import EditImage from '../RollingImages/EditImage';

import Courses from '../Courses';
import AddCourse from '../Courses/AddCourse';
import EditCourse from '../Courses/EditCourse';

import Batches from '../Batches';
import AddBatch from '../Batches/AddBatch';
import EditBatch from '../Batches/EditBatch';

import Classes from '../Classes';
import AddClass from '../Classes/AddClass';
import EditClass from '../Classes/EditClass';

import Faculties from '../Faculties';
import AddFaculty from '../Faculties/AddFaculty';
import EditFaculty from '../Faculties/EditFaculty';

import Achievers from '../Achievers';
import AddAchiever from '../Achievers/AddAchiever';
import EditAchiever from '../Achievers/EditAchiever';

import Books from '../Books';
import AddBook from '../Books/AddBook';
import EditBook from '../Books/EditBook';

import Exams from '../Exams';
import AddExam from '../Exams/AddExam';
import EditExam from '../Exams/EditExam';

import Questions from '../Questions';
import AddQuestion from '../Questions/AddQuestion';
import EditQuestion from '../Questions/EditQuestion';

import Videos from '../Videos';
import AddVideo from '../Videos/AddVideo';
import EditVideo from '../Videos/EditVideo';

import WrittenExam from '../WrittenExam';
//import AddWrittenExam from '../WrittenExam/WrittenExam';
import EditWrittenExam from '../WrittenExam/EditWrittenExam';



import Profile from '../Profile';
import { connect } from 'react-redux';

class Routes extends React.Component {

	CloseMenu () {
		$('#admin_menu_navbar').collapse('hide');
	}

	render () {
		const {adminInfo} = this.props;

		return (
			<Router>
				<nav className="navbar navbar-light" id="menu_navbar">
					<button className="navbar_btn navbar-toggler" type="button" data-toggle="collapse" data-target="#admin_menu_navbar" aria-controls="admin_menu_navbar" aria-expanded="false" aria-label="{{ __('Toggle navigation') }}">
						<img src={process.env.PUBLIC_URL + '/menu_button.png'} alt="Menu" height="30"/>
					</button>

					<div className="collapse navbar-collapse" id="admin_menu_navbar">
						<button type="button" className="close close_btn" onClick={this.CloseMenu}> <img src={process.env.PUBLIC_URL + '/close_button.png'} alt="Close" height="30"/> </button>
						<ul className="navbar-nav sticky ml-auto pt-4 mb-5 pb-5">
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/"> Dashboard </NavLink> </li>
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/classes"> Classes </NavLink> </li>
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/writtenExam"> Written Exam </NavLink> </li>


							{/*<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/batches"> Batches </NavLink> </li>*/}



							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/rolling_images"> Rolling Images </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 4) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/users"> Users </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 4) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/admissions"> Admissions </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 10) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/hostel_bookings"> Hostel Bookings </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 10) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/hostels"> Hostels </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/notifications"> Notifications </NavLink> </li>}
							{(adminInfo.user_role == 2) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/admins"> Admins </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 3) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/courses"> Courses </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 3 || adminInfo.user_role == 6 || adminInfo.user_role == 11) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/batches"> Batches </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 9) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/news_updates"> News Updates </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 3) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/faculties"> Faculties </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/achievers"> Achievers </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/books"> Books </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 7) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/exams"> Exams </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 8) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/videos"> Videos </NavLink> </li>}
							{(adminInfo.user_role == 2 || adminInfo.user_role == 5) && <li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/reports"> Reports </NavLink> </li>}
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/profile"> Profile </NavLink> </li>
						</ul>
					</div>
				</nav>

				{/* User roles */}
				{/* 1 -> Student
				2 -> Master admin
				3 -> Course admin
				4 -> Admission admin
				5 -> Reports admin
				6 -> Faculty admin
				7 -> Exams admin
				8 -> Data admin
				9 -> Others admin
				10 -> Hostel admin 
				11 -> Faculty*/}
		
				<Route exact path="/" component={Dashboard} />

				<div>
					<Route exact path="/writtenExam" component={WrittenExam} />
					<Route exact path="/writtenExam/edit_written_exam/:written_exam_id" component={EditWrittenExam} />

					<Route exact path="/classes" component={Classes} />
					<Route exact path="/batches" component={Batches} />
					<Route exact path="/batches/add_batch" component={AddBatch} />
					<Route exact path="/batches/:type/edit_batch/:batch_id" component={EditBatch} />


				</div>

	
				
				
				
				<Route exact path="/profile" component={Profile} />

				
			</Router>
		);
	}
};

const mapStatesToProps = (state) => {
	return {
		isAuth : state.admin.isAuth,
		adminInfo : state.admin.adminInfo,
	}
}

export default connect(mapStatesToProps, null) (Routes);
