import React from 'react';
import './Admin.css';
import { connect } from 'react-redux';
import { setCurrentUser, onAdminLogout, hideMessage } from '../../actions/Login';
import Routes from '../Routes/';
import $ from 'jquery';
import SetAuthToken from '../../utils/SetAuthToken';
import Images from '../../assets/images';

// const authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYzgxNDZmMDkwZmU0M2M2NWI0MDU5YmM4NGY3MjExYTY3NDFmYmE3MDI2MThmNmIzYjE0ZjQ3ZmNiNjNkYTYyMjgzNjQyNmRkMDliMzZhZWEiLCJpYXQiOjE2MDE3MTQ0NTQsIm5iZiI6MTYwMTcxNDQ1NCwiZXhwIjoxNjMzMjUwNDU0LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.gLLXBmIsAlkRb2wqDnbHppfP7VuENytw2D68SGVcUqeXNYyKtk5DUVtJcFPjIOmFiqzMY44MpADx5jjg1bZTA6XcH9ETyqCHEZSQ46FVezjYRrHgPSS-xnloA7wQ6ByZoebYdnTGqbem_n4-aN9XESlqTmnPt47cMsJnjrj0XfdypjJTAt1ZQARTIdnB3Wnc_7TBjiov7oF_X6cK0Oov4RIHWa4bs4oxeg9lDrO_7EXOcXb7IoW_8JDGToTclM-xWLiIpn0GLPKIwNx-3kBL2eaSh6I9aqs3JI59aFhaAPEklafnCU7oTqrNUK3mVHDAeIMi9FWW3d1oBzSUKtOszdSBxKSyq_VF1NVZ-GHQTu5AxtPvJ2WBitsiDTFZFZquaZi4OyOBlYScn2rijtdnEh2TRGAMoOEl5s6Cw38DrGODDzqdFCN962p5cCpyMMIDtxebOUXAgDtqCxzhnssLotyA_zggXg0ugspUULRSOE8tygdgYUArVTQcZcVY7mYUBa29eMigxnDnMHjbyeJhyArqSeCFUodut33_7PPIEH_csWd0jjL9YC3fxHiJpi8OYw-1f9ngPN9hMg72BEyszgXSNSvVsee_-q_jUjjnQkYYSPLe5Mbz3aWOGtbnnSPHvvk3168uT7ZNf4HBpE3kVTiB547T04D0U33Y5Q-Xd0s';

class Admin extends React.Component {
	
	constructor(props){
		super(props);
		this.onHandleLogout = this.onHandleLogout.bind(this);
	}

	componentWillMount () {

		SetAuthToken(localStorage.getItem('token'));
		// SetAuthToken(authToken);

		if(localStorage.getItem('adminInfo') && localStorage.getItem('token')) {
			this.props.onSetCurrentUser(JSON.parse(localStorage.getItem('adminInfo')));
		}
	}

	onHandleLogout (e) {
		e.preventDefault();
		this.props.onLogoutClick();
	}

	render() {
	
		let message_display = '';
		const { message, error, adminInfo } = this.props;

		// if(message !== ''){
		// 	message_display = <div className="alert alert-success">
		// 		<span className="small_text"> {message} </span>
		// 	</div>;
		// }

		// if(error !== ''){
		// 	message_display = <div className="alert alert-danger">
		// 		<span className="small_text"> {error} </span>
		// 	</div>;
		// }

		return (
			<div className="container-fluid">
				<div className="row" id="menu_container">
					<div className="col"></div>
					<div className="col text-center">
						{/* <span className="text-uppercase" style={{color : '#233f78', fontSize:'20px', fontWeight:'800'}}> CLASSIC </span> */}
						<img src={Images.logo} className="header_lgoo" alt="Logo"/>
						{/* <span className="text-uppercase" style={{color : '#233f78', fontSize:'20px', fontWeight:'800'}}> EDUCATION </span> */}
					</div>
					<div className="col">
						<button className="float-right btn btn-danger btn-sm" onClick={this.onHandleLogout}><b> LOGOUT <i className="fa fa-sign-out"></i> </b></button>
						<h6 className="mt-3 float-right" style={{color: '#233f78'}}> Hello { adminInfo && adminInfo.faculty_name}, </h6>
					</div>
				</div>
				<div className="row pt-5">
					<div className="col pt-3">

						{/* Error or success messages display */}
						<div className="message_error_container">
							{
								message && message !== '' ?
									<span className="message_text"> <i className="fa fa-check-circle"></i> {message} </span>
								: ''
							}
							{
								error && error !== '' ? 
									<span className="error_text"> <i className="fa fa-exclamation-circle"></i> {error} </span>
								: ''
							}
						</div>

						{/* Including routing components */}
						<Routes />
						
					</div>
				</div>
			</div>
		);
	}

	componentDidUpdate () {
		// Clear forms input if success
		if(this.props.message && this.props.message.length > 2) {
			$('.add_container .form-control').val('');
		}

		if(this.props.error !== '' || this.props.message !== '') {
			setTimeout( function () { this.props.onHideMessage() }.bind(this), 2000);
		}
	}
	
}

const mapStatesToProps = state => {
	return {
		isLoading : state.admin.isLoading,
		message : state.admin.message,
		error : state.admin.error,
		isAuth : state.admin.isAuth,
		adminInfo : state.admin.adminInfo,
	}
};

const mapActionsToProps = {
	onLogoutClick : onAdminLogout,
	onSetCurrentUser : setCurrentUser,
	onHideMessage : hideMessage
};

export default connect(mapStatesToProps, mapActionsToProps) (Admin);