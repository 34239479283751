import server from '../utils/Server';
import { onGetFacultiesSuccess } from './WrittenExam';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_WRITTEN_EXAMS_SUCCESS = 'GET_WRITTEN_EXAMS_SUCCESS';
export const GET_WRITTEN_EXAM_SUCCESS = 'GET_WRITTEN_EXAM_SUCCESS';

export const getMyBatchWrittenExams = (facultyID) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getFacultyWrittenExam/' + facultyID)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onGetWrittenExamsSuccess(response.data.data));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                    // dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

/*
export const getAllPresenters = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetPresenter')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onGetPresentersSuccess(response.data.data));
                }
                else {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}


export const getBatchFaculty = (batchID) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getBatchFaculty/'+batchID)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onGetPresentersSuccess(response.data.data));
                }
                else {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}


export const addNewClass = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        //ScheduleDailyClass
        return server.post('/ScheduleOnlineClass', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getClassDetails = (class_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditClass/' + class_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetClassSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}


export const deleteClass = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/CancelClass', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllClasses());
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message));}, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

*/

export const updateWrittenExam = (submission_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.post('/updateFacultyWrittenExamSubmission/' + submission_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};




export const getWrittenExamDetail = (submission_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/getFacultyWrittenExamDetail/' + submission_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    console.log("testinggg",response.data.data);
                    dispatch(onGetWrittenExamSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}




export const onGetWrittenExamsSuccess = (data) => {
    return {
        type : GET_WRITTEN_EXAMS_SUCCESS,
        payload : {
            writtenExams : data
        }
    }
}

export const onGetWrittenExamSuccess = (data) => {
    return {
        type : GET_WRITTEN_EXAM_SUCCESS,
        payload : {
            writtenExam : data
        }
    }
}
/*
export const onGetPresentersSuccess = (presenters) => {
    return {
        type : GET_PRESENTER_SUCCESS,
        payload : {
            presenters : presenters
        }
    }
}*/
