import axios from 'axios';

//export const BaseUrl = 'https://api.classicedu.org/public/';
export const BaseUrl = 'https://api.classicedu.org/public/';
//export const BooksUrl = BaseUrl + 'book_files/';


const server = axios.create({
    baseURL : 'https://api.classicedu.org/public/api',
    headers : { 
        'Accept' : 'application/json',
        'Content-Type' : 'application/x-www-form-urlencoded'
    }
});

export default server;