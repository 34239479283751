import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMyBatchWrittenExams } from '../../actions/WrittenExam';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';
import * as moment from 'moment'
import {BaseUrl} from '../../utils/Server';
import Images from '../../assets/images';


moment.locale();  // en



class WrittenExam extends React.Component {
  constructor(props){
    super(props);
    const {params} = props.match;
    this.state = { activePage : 1, batch_id : params.batch_id };
    this.deleteClass = this.deleteClass.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {

    const {adminInfo } = this.props;

    this.props.getMyBatchWrittenExams( adminInfo.id );
  }


   //downloadFile(path) {

       //downloadFile = (path) => {


        /*const source = BaseUrl + path
         //window.location.href = path;
        //console.log(path, source)
        //download(path1);

        const fileName = source.split('/').pop();
        var el = document.createElement("a");
        el.setAttribute("href", source);
        el.setAttribute("target", '_blank');
        el.setAttribute("download", fileName);
        document.body.appendChild(el);
        el.save()
         el.click();
        el.remove();


      }*/


    download = (source) =>{

        const fileName = source.split('/').pop();
        var el = document.createElement("a");
        el.setAttribute("href", source);
        el.setAttribute("target", '_blank');
        el.setAttribute("download", fileName);
        document.body.appendChild(el);
        //el.save()

         el.click();
        el.remove();
         //document.body.removeChild(el) 

         console.log("download url",fileName); 

    }
    
  downloadFile = (path) => {
        const _path = BaseUrl + path
        //window.location.href = path;
        console.log(path, _path)
        this.download(_path);
      }

   
     


  deleteClass = (class_id) => {
    swal({
      title: "Are you sure?",
      text: "To cancel and delete this class?",
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData();
        formData.append('class_id', class_id);

        this.props.onDeleteClass(formData);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    const {adminInfo } = this.props;

    this.props.getMyBatchWrittenExams(adminInfo.id, '?' + params);
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value);
    }
    var params = $('#search_filter_form').serialize();
     const {adminInfo } = this.props;
    this.props.getMyBatchWrittenExams(adminInfo.id, '?' + params);
  }

  render() {

    const { writtenExams, total_count, page_count, page_size, base_url , adminInfo} = this.props;
    const { batch_id } = this.state;


    let classes_display = {};
    let pagination_display = '';

    let classRepeatTypes = ['', 'Daily (All 7 Days)', 'Monday to Saturday', 'Monday to Friday', 'Weekly Once', 'Monthly Once'];
    
    //console.log("classes", writtenExams);

    if((writtenExams && writtenExams.length > 0 ))
    {
        var index = 0;
        classes_display =  writtenExams.map((writtenExam) => {

        index++;

      
          return (
            <tr key={index}>
              <td>{index}</td>
              <td>{writtenExam.written_exam_title}</td>
              <td>{ moment( writtenExam.written_exam_validity ).format('DD-MM-YYYY') }</td>
              <td>{writtenExam.course_name}</td>
              <td>{writtenExam.batch_name}</td>
              <td>{writtenExam.name}</td>

              <td>{writtenExam.written_exam_question && <img src={Images.download} height={"30"} onClick={() => this.downloadFile(writtenExam.written_exam_question)} />}</td>
              <td>{writtenExam.answer_paper && <img src={Images.download} height={"30"} onClick={() => this.downloadFile(writtenExam.answer_paper)} />}</td>

              <td>{writtenExam.written_exam_marks}</td>
              <td>{writtenExam.obtained_marks}</td>
               <td>{writtenExam.submission_status}</td>

               {/*<td><a href={classe.attendee_url} target="_blank"><button type="button" className="btn btn-primary btn-sm" title="Start"><b>Start</b></button></a></td> 
             <td><button className="icon_button" title="Delete" onClick={() => this.deleteClass(classe.class_id)}> <i className="fa fa-close text-danger"></i> </button></td>*/}
            </tr>
          )

      })

    }
    else
    {
      classes_display = <tr><td colSpan="12" className="text-center"> No written exam submission found.</td></tr>
    }


  if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} classes. </p> */}
          <p className="small_text d-inline ml-2"> Showing {writtenExams.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} classes. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {writtenExams.length} results. </p>
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  {/*<li className="breadcrumb-item"><Link to="/batches">Batches</Link> </li>*/}
                  <li className="breadcrumb-item active" area-current="page">Written Exam</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Written Exam Submission </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              {/*<Link to={"/classes/" + batch_id + "/add_class"}><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link> */}
            </div>
          </div>

           {/* filters, sort and search container */}
           {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Classes A-Z</option>
                    <option value="2">Classes Z-A</option>
                    <option value="3">Newest First</option>
                    <option value="4">Oldest First</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <input type="hidden" name="page_size" id="page_size" value={page_size}/>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by class name" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          {/* Display all classes table */}
          <table className="table table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No.</th>
                <th className="bg-blue text-light" width="10%">Exam Title</th>
                <th className="bg-blue text-light" width="10%">Exam Validity</th>
                <th className="bg-blue text-light" width="10%">Course</th>
                <th className="bg-blue text-light" width="10%">Batch</th>
                <th className="bg-blue text-light" width="15%">Student </th>
                <th className="bg-blue text-light" width="10%">Question Paper </th>
                <th className="bg-blue text-light" width="10%">Answer Paper </th>
                <th className="bg-blue text-light" width="10%">Evalution Paper </th>
                <th className="bg-blue text-light" width="5%">Max Marks</th>
                 <th className="bg-blue text-light" width="5%">Obtained Marks</th> 
                 <th className="bg-blue text-light" width="10%">Status</th> 
              </tr>
            </thead>
            <tbody>

              {/* Display all the classes */}
              {classes_display}

            </tbody>
          </table>

          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    writtenExams : state.writtenExam.writtenExams,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
    base_url: state.admin.base_url,
    adminInfo: state.admin.adminInfo,

  }
};

const mapActionsRoProps = {
  getMyBatchWrittenExams : getMyBatchWrittenExams,
  //onDeleteClass : deleteClass
};

export default connect(mapStatesToProps, mapActionsRoProps) (WrittenExam);
