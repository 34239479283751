import React from 'react';
import './Admissions.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewAdmission } from '../../actions/Admissions';
import {getCoursesWithBatches} from '../../actions/Courses';
import {getAllUsers} from '../../actions/Users';
import { onDisplayMessage, onDisplayError, onLoadingTrue, onLoadingFalse } from '../../actions/Login';
import swal from 'sweetalert';
import Server from '../../utils/Server';
import AppConstants from '../../assets/constants/';
import Images from '../../assets/images/';

class AddAdmission extends React.Component {

  constructor(props){
    super(props);
    this.state = {courseType: '', courseID: 0, userID: 0, error: '', userData: {name: '', email: '', mobile_number: props.match.params.mobile_number.length === 10 ? props.match.params.mobile_number : '', dob: '', gender: 'Male', referral: '', communication_address: '', permanent_address: ''}, courses: [], batches: [], batchID: 0, batchData: {}, totalAmount: 0, balanceAmount: 0, orderPrice: 0, paymentType: '', activePayment: {}};
  }

  componentDidMount() {
    if(this.state.userData && this.state.userData.mobile_number.length === 10) {
      this.handleChangeMobileNumber(this.state.userData.mobile_number);
    }
  }

  handleFreshAdmission = () => {
    window.location.hash = "#/admissions/add_admission/1";
    window.location.reload();
  }

  handleChangeMobileNumber = (mobileNumber) => {
      this.setState({userData: {name: '', email: '', mobile_number: mobileNumber}, userID: 0, batchID: 0, courseID: 0, batchData: {}, courses: [], batches: [], totalAmount: 0, balanceAmount: 0, orderPrice: 0, paymentType: '', activePayment: {}});

      if(mobileNumber.length !== 10) {
          this.setState({error: 'Enter valid 10 digit mobile number'});
      } else {
          this.setState({error: ''});

          // Push to URL of it's different mobile number
          if(this.props.match.params.mobile_number !== mobileNumber) {
            window.location.hash = '#/admissions/add_admission/' + mobileNumber;
            window.location.reload();
          }

          this.props.onLoadingTrue();
          Server.get('/GetBatchPayments?mobile_number=' + mobileNumber)
              .then(response => {
                  this.props.onLoadingFalse();
                  const {data, message, statuscode} = response.data;
                  
                  if(statuscode === 200) {
                    // Update user data
                    if(data.user && Object.entries(data.user).length > 0) {
                      this.setState({userData: data.user, userID: data.user.id, userPayments: data.batches});
                    }
                  } else {
                    this.setState({error: 'User not yet registered'});
                  }
              })
          .catch(error => {
              this.props.onLoadingFalse();
              this.props.onDisplayError('Failed, Try again later');
          });
      }
  }

  onChangeHandler = (type, event) => {
    const {courses, batches, userData} = this.state;
    const {name, value} = event.target;
    
    if(type === 0) {

      if(value.length !== 10) {
        this.setState({userData: {name: '', email: '', mobile_number: value, dob: '', gender: 'Male', referral: '', communication_address: '', permanent_address: ''}, userID: 0});
      } else {
        // this.props.onGetBatchPayments(value);
        // if(users && users.length > 0) {
        //   var userExists = users.findIndex(x => x.mobile_number == value);

        //   // If already user exists else add as new user
        //   if(userExists >= 0) {
        //     this.setState({userData: users[userExists], userID: users[userExists].id});
        //   } else {
        //     userData[name] = value;
        //     this.setState({userData: userData, userID: 0});
        //   }
        // } else {
        //   userData[name] = value;
        //   this.setState({userData: userData, userID: 0});
        // }
      }
    } else if(type === 1) {
      userData[name] = value;
      this.setState({userData: userData, userID: 0});
    } else if(type === 2) {
      this.handleGetCourseBatches(value, 0, 0, {});
    } else if(type === 3) {
      var course_index = courses.findIndex(x => x.id == value);
      this.setState({courseID: value, batches: courses[course_index].batches, batchID: 0, batchData: {}, totalAmount: 0});
    } else if(type === 4) {
      var batch_index = batches.findIndex(x => x.id == value);
      this.setState({batchID: value, batchData: batches[batch_index], totalAmount: batches[batch_index].total_fees});
    } else if(type === 5) {
      this.setState({paymentType: value});
    } else if(type === 6) {
      this.setState({orderPrice: value});
    }
  }

  handleGetCourseBatches = async (courseType, courseID, batchID, activePayment) => {
    this.setState({courseType: courseType, courseID: 0, batches: [], batchID: 0, batchData: {}, totalAmount: 0, activePayment: activePayment});

    this.props.onLoadingTrue();
    await Server.get('/GetCourseWithBatches/' + courseType)
      .then(response => {
          this.props.onLoadingFalse();
          if(response.data.statuscode === 200){
              const coursesData = response.data.data;
              // this.props.onGetCoursesSuccess(response.data.data);
              this.setState({courses: coursesData}, () => {
                window.scrollTo(0, 0) // Move to top

                // Show courses, batch, fees based on selection in payment history
                if(courseID !== '') {
                  var course_index = coursesData.findIndex(x => x.id == courseID);

                  if(course_index >= 0) {
                    var batchesData = coursesData[course_index].batches;
                    this.setState({courseID: courseID, batches: coursesData[course_index].batches, batchID: 0, batchData: {}, totalAmount: 0});

                    var batch_index = batchesData.findIndex(x => x.id == batchID);
                    if(batch_index >= 0) {
                      var batchData = batchesData[batch_index];
                      this.setState({batchID: batchID, batchData: batchData, totalAmount: batchData.total_fees});
                    }
                  }

                }
              });
          }
          else{
              this.props.onDisplayError(response.data.message);
          }
      })
      .catch(err => {
        this.props.onLoadingFalse();
        this.props.onDisplayError('Failed, try again later');
      });
  }

  onAddAdmissionSubmit = (e) => {
    e.preventDefault();

    const {paymentType, totalAmount, orderPrice, userData} = this.state;
    var formData = new FormData(document.getElementById('add_admission_form'));

    if(paymentType != '' && paymentType == 0) {
      swal({
        title: "Are you sure?",
        text: "To add this new admission?",
        buttons: ['Cancel', 'Add'],
      })
      .then(willDelete => {
        if (willDelete) {
          this.props.onAddNewAdmission(formData);
        }
      });
    } else {
      // Payment gateway if payment type is online payment
      formData.append('order_price', 1);

      swal({
        title: "Are you sure?",
        text: "To add this new admission?",
        buttons: ['Cancel', 'Add'],
      })
      .then(willDelete => {
        if (willDelete) {
          Server.post('/AdminSaveCheckout', formData)
          .then(response => {
            if(response.data.statuscode === 200)
            {
              // Initialize payment
              var options = {
                "key": AppConstants.RAZORPAY_API_KEY,
                // "amount": (totalAmount * 100),
                "amount": 1,
                "name": 'Batch Admission',
                "description": 'Classic Education Dharwad',
                "image": Images.logo,
                "order_id" : response.data.rzpay_order_id,
                "currency": 'INR',
                "theme" : {
                  "color": "#44a1a0"
                },
                "handler": function (response){
                  if(response && response.razorpay_payment_id !== '')
                  {
                    // Make payment success in server

                    formData.append('rzpay_order_id', response.razorpay_order_id);
                    formData.append('rzpay_payment_id', response.razorpay_payment_id);
                    formData.append('rzpay_signature', response.razorpay_signature);
                    formData.append('payment_type', 1);

                    Server.post('/AdminPaymentSuccess', formData)
                    .then(response => {
                      if(response.data.statuscode === 200) {
                        swal({ title :'Thank you', text : response.data.message, icon : 'success'});
                        setTimeout(() => window.location.reload(), 1000);
                      } 
                    })
                    .catch(error => {
                      swal('OOPS!...', error.message);
                    });
                  }
                },
                "prefill" : {
                  "contact" : userData.mobile_number,
                  "email" : userData.email
                }
              }
      
              let rzp = new window.Razorpay(options); rzp.open();
            }
          })
          .catch(error => {
            swal('OOPS!...', error.message);
          });
        }
      });
    }
  }

  renderCurrency = (type, amount) => {
    if(amount > 0) {
      return (
        <tr>
          <td className="p-2"><h6 className="text-blue m-0">{type}</h6></td>
          <td className="p-2"><h6 className="text-blue m-0">{'Rs. ' + amount.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}</h6></td>
        </tr>
        
      )
    }
  }

  render() {
    const {userID, userData, courses, batches, batchData, batchID, error, totalAmount, balanceAmount, paymentType, orderPrice, userPayments, userBatches, courseType, courseID, activePayment} = this.state;
    const {users} = this.props;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/admissions">Admissions</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">New Admission</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> New Admission </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <button type="button" className="btn btn-primary btn-sm" onClick={() => this.handleFreshAdmission()}>Fresh Admission <i className="ml-1 fa fa-user-plus"></i></button>
              <Link to="/users/add_user"><button type="button" className="btn btn-primary btn-sm">Add User <i className="ml-1 fa fa-user-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Add new admission form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 card p-3">
              <br/>

              <form id="add_admission_form" onSubmit={this.onAddAdmissionSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div className="form-group">
                      <label className="small_text"> Mobile Number * : </label>
                      <input type="number" name="mobile_number" placeholder="Mobile Number *" className="form-control" value={userData && userData.mobile_number ? userData.mobile_number : ''} onChange={(e) => this.handleChangeMobileNumber(e.target.value)} autoFocus required autoComplete="off"/>
                      {error !== '' ? <span className="small_text text-danger">{error}</span> : ''}
                      <label className="small_text">Note: Details will fill automatically if user already exists </label>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Full Name * : </label>
                      <input type="text" name="name" placeholder="Full Name *" className="form-control" value={userData && userData.name ? userData.name : ''} onChange={(e) => this.onChangeHandler(1, e)} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Email ID * : </label>
                      <input type="email" name="email" placeholder="Email ID *" className="form-control" value={userData && userData.email ? userData.email : ''} onChange={(e) => this.onChangeHandler(1, e)} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Date of Birth : </label>
                      <input type="date" name="dob" placeholder="Date of Birth" className="form-control" value={userData && userData.dob ? userData.dob : ''} onChange={(e) => this.onChangeHandler(1, e)}/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Course Type * : </label>
                      <select className="form-control" value={courseType} onChange={(e) => this.onChangeHandler(2, e)} required>
                        <option value=""> Select Course Type </option>
                        <option value="0"> Offline Course </option>
                        <option value="1"> Online Course </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Course * : </label>
                      <select className="form-control" value={courseID} onChange={(e) => this.onChangeHandler(3, e)} required>
                        <option value=""> Select Course </option>

                        {
                          courses && courses.length > 0 && 
                            courses.map((course, index) => (
                              <option key={index} value={course.id}>{course.course_name}</option>
                            ))
                        }
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Batches * : </label>
                      <select className="form-control" value={batchID} onChange={(e) => this.onChangeHandler(4, e)} required>
                        <option value=""> Select Batch * </option>

                        {
                          batches && batches.length > 0 && 
                            batches.map((batch, index) => (
                              <option key={index} value={batch.id}>{batch.batch_name}</option>
                            ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div className="row form-group">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Gender : </label>
                        <select name="gender" className="form-control" value={userData && userData.gender ? userData.gender : ''} onChange={(e) => this.onChangeHandler(1, e)} required>
                          <option value=""> Select Gender </option>
                          <option value="Male"> Male </option>
                          <option value="Female"> Female </option>
                        </select>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="small_text"> Referral Code : </label>
                        <input type="text" name="referral" placeholder="Referral Code" className="form-control" value={userData && userData.referral ? userData.referral : ''} onChange={(e) => this.onChangeHandler(1, e)}/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Communication Address : </label>
                      <textarea rows={5} name="communication_address" placeholder="Communication Address" className="form-control" value={userData && userData.communication_address ? userData.communication_address : ''} onChange={(e) => this.onChangeHandler(1, e)}/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Permanent Address : </label>
                      <textarea rows={5} name="permanent_address" placeholder="Permanent Address" className="form-control" value={userData && userData.permanent_address ? userData.permanent_address : ''} onChange={(e) => this.onChangeHandler(1, e)}/>
                    </div>

                    <div className="form-group">
                      <label className="small_text"> Payment Type * : </label>
                      <select name="payment_type" className="form-control" onChange={(e) => this.onChangeHandler(5, e)} required>
                        <option value=""> Select Payment Type </option>
                        <option value="0"> Offline Payment </option>
                        <option value="1"> Online Payment </option>
                      </select>
                    </div>

                    {
                      paymentType != '' && paymentType == 0 && 
                        <div className="form-group">
                          <label className="small_text"> Receipt Number : </label>
                          <input type="text" name="receipt_number" placeholder="Receipt Number" className="form-control"/>
                        </div>
                    }
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <h6 className="text-center text-blue">BATCH FEES DETAILS</h6>
                    <hr/>
                    <div>
                      <table className="table table-bordered">
                        <tbody>
                          {(batchData && Object.entries(batchData).length > 0) ?
                            <tr>
                              <td className="p-2"><h6 className="text-blue m-0">{'Fees'}</h6></td>
                              <td className="p-2"><h6 className="text-blue m-0">{'Amount'}</h6></td>
                            </tr>
                          : <tr>
                              <td rowSpan={2} className="p-2"><h6 className="text-blue m-0">{'Fees details not available'}</h6></td>
                            </tr>
                          }

                          {/* {this.renderCurrency('Admission', batchData ? batchData.admission_fees : 0)}
                          {this.renderCurrency('Tution', batchData ? batchData.tution_fees : 0)}
                          {this.renderCurrency('Books', batchData ? batchData.books_fees : 0)}
                          {this.renderCurrency('Hostel', batchData ? batchData.hostel_fees : 0)}
                          {this.renderCurrency('Meal', batchData ? batchData.meal_fees : 0)}
                          {this.renderCurrency('Transportation', batchData ? batchData.transportation_fees : 0)}
                          {this.renderCurrency('Online', batchData ? batchData.online_fees : 0)}
                          {this.renderCurrency('Other', batchData ? batchData.other_fees : 0)} */}
                          {this.renderCurrency('Total Fees', batchData ? batchData.total_fees : 0)}
                          {this.renderCurrency('Total Paid ', (activePayment ? activePayment.total_paid_price : 0))}
                          {this.renderCurrency('Balance Fees', (batchData && activePayment) ? (parseInt(activePayment.total_fees) - parseInt(activePayment.total_paid_price)) : activePayment.total_fees)}
                        </tbody>
                      </table>
                    </div>

                    <div className="form-group mt-5">
                      <label className="small_text text-center">Enter Current Payable Amount (Amount which you want to pay with this transaction) * : </label>
                      <input type="number" name="order_price" placeholder="Current Payable Amount" className="form-control" value={orderPrice ? orderPrice : 0} onChange={(e) => this.onChangeHandler(6, e)} required/>
                    </div>
                  </div>
                </div>

                <input type="hidden" name="id" value={batchID} />
                <input type="hidden" name="order_type" defaultValue={'1'} />

                <div className="form-group text-center mt-4">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Submit Admission </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

          {/* Display all payments history */}
          <div className="row mt-4">
            <div className="col">
                <h4 className="text-center">{'Payment History'}</h4>
            </div>
          </div>
          
          {(userPayments && userPayments.length > 0) ? 
              userPayments.map((payment, index) => (
                <div key={index} className="row mt-2 mb-5">
                    <div className="col" />
                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 card p-3">
                      <div className="row">
                        <div className="col">
                          <h6 className="text-blue">{'Course: ' + payment.course_name}</h6>
                          <h6 className="text-blue">{'Batch: ' + payment.batch_name}</h6>
                          <h6 className="text-blue">{'Type: ' + (payment.batch_type == '1' ? 'Online' : 'Offline')}</h6>
                        </div>
                        <div className="col" />
                        <div className="col text-right">
                          <button className="btn btn-primary btn-md w-50" disabled={(parseInt(payment.total_fees) - parseInt(payment.total_paid_price)) === 0} onClick={() => this.handleGetCourseBatches(payment.course_type, payment.course_id, payment.batch_id, payment)}>{(parseInt(payment.total_fees) - parseInt(payment.total_paid_price)) ? ('PAY BALANCE - Rs. ' + (parseInt(payment.total_fees) - parseInt(payment.total_paid_price))) : 'PAID'}</button>
                        </div>
                      </div>

                      <table className="table table-responsive table-bordered table-striped mt-2">
                        <thead w="100%">
                          <tr>
                            <th className="bg-blue text-light" width="5%">Sl.No.</th>
                            <th className="bg-blue text-light" width="20%">Order ID</th>
                            <th className="bg-blue text-light" width="15%">Payment Type</th>
                            <th className="bg-blue text-light" width="20%">Total Amount</th>
                            <th className="bg-blue text-light" width="20%">Paid Amount</th>
                            <th className="bg-blue text-light" width="15%">Paid Date</th>
                          </tr>
                        </thead>
                        <tbody>

                          {/* Display all the bookings */}
                          {(payment && payment.transactions.length > 0) ? 
                            payment.transactions.map((transac, traIndex) => (
                              <tr key={traIndex}>
                                <td>{traIndex+1}</td>
                                <td>{transac.order_id}</td>
                                <td>{transac.payment_type === '1' ? 'Online' : 'Offline'}</td>
                                <td>{'Rs. ' + transac.total_fees}</td>
                                <td>{'Rs. ' + transac.order_price}</td>
                                <td>{transac.ordered_date}</td>
                              </tr>
                            ))
                          : 
                            <tr><td colSpan="10" className="text-center">{'No transactions available'}</td></tr>
                          }

                        </tbody>
                      </table>
                  </div>
                  <div className="col" />
              </div>
            ))
          : 
            <div className="row mt-2 mb-4">
              <div className="col" />
              <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12 card py-5">
                <h6 className="text-center">{'No batches registered.'}</h6>
              </div>
              <div className="col" />
            </div>
          }
          <br/>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
    return {
        isLoading: state.admin.isLoading,
        courses: state.course.courses,
        users: state.user.users,
        payments: state.admission.payments,
    }
};

const mapActionsRoProps = {
    onGetUsers: getAllUsers,
    onAddNewAdmission: addNewAdmission,
    onGetCoursesWithBatches: getCoursesWithBatches,
    onDisplayMessage: onDisplayMessage,
    onDisplayError: onDisplayError,
    onLoadingTrue: onLoadingTrue,
    onLoadingFalse: onLoadingFalse
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddAdmission);