import server from '../utils/Server';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from './Login';
import { addPaginationValues } from './Pagination';

export const GET_ADMISSIONS_SUCCESS = 'GET_ADMISSIONS_SUCCESS';
export const GET_ADMISSION_SUCCESS = 'GET_ADMISSION_SUCCESS';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';

export const getAllAdmissions = (params) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetAllAdmissions' + params)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200) {
                    dispatch(onGetAdmissionsSuccess(response.data.data));
                    // dispatch(onGetStatesSuccess(response.data.states));
                    // dispatch(onGetMemberPlansSuccess(response.data.plans));
                    // dispatch(addPaginationValues(response.data.total_count, response.data.page_count, response.data.page_size));
                }
                else {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const getBatchPayments = (userID, batchID) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/GetBatchPayments/' + userID + '/' + batchID)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetPaymentsSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const addNewAdmission = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/AdminPaymentSuccess', formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                    window.location.reload();
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const getAdmissionDetails = (admission_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return server.get('/EditAdmission/' + admission_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200){
                    dispatch(onGetAdmissionSuccess(response.data.data));
                }
                else{
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    }
}

export const updateAdmission = (admission_id, formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/UpdateAdmission/' + admission_id, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const deleteAdmission = (type, admission_id) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());

        return server.post('/DeleteAdmission/' + admission_id)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statuscode === 200)
                {
                    dispatch(getAllAdmissions(type, ''));
                    setTimeout( function () { dispatch(onDisplayMessage(response.data.message)); }, 1500);
                }
                else
                {
                    dispatch(onDisplayError(response.data.message));
                }
            })
            .catch(error => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, Try again later'));
            });
    };
};

export const onGetAdmissionsSuccess = (admissions) => {
    return {
        type : GET_ADMISSIONS_SUCCESS,
        payload : {
            admissions : admissions
        }
    }
}

export const onGetAdmissionSuccess = (admission) => {
    return {
        type : GET_ADMISSION_SUCCESS,
        payload : {
            admission : admission
        }
    }
}

export const onGetPaymentsSuccess = (payments) => {
    return {
        type : GET_PAYMENTS_SUCCESS,
        payload : {
            payments : payments
        }
    }
}
