import React from 'react';
import './Users.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllUsers, deleteUser } from '../../actions/Users';
import { onLoadingTrue, onLoadingFalse, onDisplayMessage, onDisplayError } from '../../actions/Login';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import $ from 'jquery';
import server from '../../utils/Server';
import {SortItems, SearchItems} from '../../utils/DataTable';

class Users extends React.Component {
  constructor(props){
    super(props);
    this.state = {activePage : 1, downloadFile: '', sortKey: 1, searchKey: '', pageNo: 1};
    this.deleteUser = this.deleteUser.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount () {
	  this.props.onGetUsers(1, '');
  }

  deleteUser = (user_id) => {
    swal({
      title: "Are you sure?",
      text: "To delete this user permanently?",
      dangerMode: true,
	    buttons: ['Cancel', 'Delete'],
    })
    .then(willDelete => {
      if (willDelete) {
        this.props.onDeleteUser(1, user_id);
      }
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var params = $('#search_filter_form').serialize();
    params += '&page_no=' + pageNumber;
    this.props.onGetUsers(1, '?' + params);
  }

  handleDownloadExcel = () => {
    swal({
      title: "Are you sure?",
      text: "To download all users list?",
      buttons: ['Cancel', 'Download'],
    })
    .then(willDownload => {
      if (willDownload) {
        this.props.onLoadingTrue();
        server.get('/download_users_excel')
            .then(response => {
                this.props.onLoadingFalse();
                if(response.data.statuscode === 200) {

                  // Create anchor tag and download file
                  const link = document.createElement('a');
                  link.href = 'https://api.classicedu.org/public/' + response.data.file;
                  link.download = response.data.file;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);

                  setTimeout( this.props.onDisplayMessage(response.data.message), 5000);
                }
                else
                  this.props.onDisplayError(response.data.error);
            })
            .catch(error => {
              this.props.onLoadingFalse();
              this.props.onDisplayError(error.message);
            });
      }
    });
  }
 
  onApplyFilters = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });

    if(e.target.name === 'page_size_changer'){
      $('#page_size').val(e.target.value); 
    }
    var params = $('#search_filter_form').serialize();
    this.props.onGetUsers(1, '?' + params);
  }

  handleSorting = (sortKey) => {
    this.setState({sortKey: sortKey});
  }

  handleSearch = (searchKey) => {
    this.setState({searchKey: searchKey.toLowerCase()});
  }

  renderUsersList = () => {
    const { base_url } = this.props;
    const {searchKey, pageNo} = this.state;
    var users = this.props.users;
    var sortKey = parseInt(this.state.sortKey);

    // Sorting
    if (sortKey === 1) {
      SortItems(users, 'id', 2, 2);
    } else if(sortKey === 2) {
      SortItems(users, 'id', 2, 1);
    } else if (sortKey === 3) {
      SortItems(users, 'name', 1, 2);
    } else if(sortKey === 4) {
      SortItems(users, 'name', 1, 1);
    } else if(sortKey === 5) {
      SortItems(users, 'status', 1, 1);
    } else if(sortKey === 6) {
      SortItems(users, 'status', 1, 2);
    }

    // Searching
    if (searchKey !== "") {
      // users = SearchItems(users, ['name', 'email', 'mobile_number'], searchKey);
      users = users.filter(user => (user.name.toLowerCase().includes(searchKey) || user.email.toLowerCase().includes(searchKey) || user.mobile_number.toString().includes(searchKey)));
    }

    if(users && users !== '' && users.length > 0) {
      return (
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.mobile_number}</td>
              <td>{user.profile_pic ? <img src={base_url + user.profile_pic} height="40" alt="" /> : ''}</td>
              <td>{user.status === '1' ? 'Active' : 'Inactive'}</td>
              <td><Link to={'/users/edit_user/' + user.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>
              {/* <td><button className="icon_button" title="Delete" onClick={() => this.deleteUser(user.id)}> <i className="fa fa-trash text-danger"></i> </button></td> */}
            </tr>
          ))}
        </tbody>
      )
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="8" className="text-center"> No users found.</td>
          </tr>
        </tbody>
      )
    }
  }

  render() {
    const { users, states, plans, total_count, page_count, sortKey, searchKey, page_size } = this.props;

    let pagination_display = '';
    let states_list = [];
    let plans_list = [];

    // User states for filters
    if(states && states !== '' && states.length > 0)
    {
      states_list = states.map((state) => (
        <option key={state.id} value={state.id}> {state.state_name} </option>
      ));
    }

    // User plans for filters
    if(plans && plans !== '' && plans.length > 0)
    {
      plans_list = plans.map((plan) => (
        <option key={plan.id} value={plan.id}> {plan.plan_name} </option>
      ));
    }

    // Show pagination only if values are more than 10
    if(total_count > 10)
    {
      pagination_display = <div className="row pt-1">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
          <div className="form-group d-inline">
            <label className="small_text">Page size : </label>
            <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.onApplyFilters}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          {/* <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} users. </p> */}
          <p className="small_text d-inline ml-2"> Showing {users.length} results. </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
          <div className="pagination_container table-responsive">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={page_size}
              totalItemsCount={total_count}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    }
    else
    {
      // pagination_display = <p className="small_text d-inline ml-2"> Showing {page_count} of {total_count} users. </p>
      pagination_display = <p className="small_text d-inline ml-2"> Showing {users.length} results. </p>
    }

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Users</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Users </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/admissions/add_admission/1"><button type="button" className="btn btn-primary btn-sm">Add Admission <i className="ml-1 fa fa-plus"></i></button></Link>
              <Link to="/users/add_user"><button type="button" className="btn btn-primary btn-sm">Add User <i className="ml-1 fa fa-user-plus"></i></button></Link>
            </div>
          </div>

          {/* filters, sort and search container */}
          <div id="search_filter_form">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSorting(e.target.value)}>
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Names A-Z</option>
                    <option value="4">Names Z-A</option>
                    <option value="5">Active First</option>
                    <option value="6">Inactive First</option>
                  </select>
                </div>
              </div>

              <div className="col-md-2 col-12" />
              <div className="col text-right">
                <div className="input-group mb-3">
                <div className="input-group-append">
                    <button type="button" className="search_btn btn-primary px-3"><i className="fa fa-search"></i></button>
                  </div>
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name, email or mobile number" value={searchKey} onChange={(e) => this.handleSearch(e.target.value)} autoComplete="off"/>
                 
                </div>
              </div>
            </div>
          </div>
          
          {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group d-inline">
                  <label className="small_text">Sort by : </label>
                  <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                    <option value="1">Newest First</option>
                    <option value="2">Oldest First</option>
                    <option value="3">Users A-Z</option>
                    <option value="4">Users Z-A</option>
                  </select>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"></div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                <div className="input-group mb-3">
                  <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name, email, phone" autoComplete="off"/>
                  <div className="input-group-append">
                    <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          {/* Display all users table */}
          <table className="table table-bordered table-striped">
            <thead w="100%">
              <tr>
                <th className="bg-blue text-light" width="5%">Sl.No</th>
                <th className="bg-blue text-light" width="36%">Full Name</th>
				        <th className="bg-blue text-light" width="20%">Email</th>
                <th className="bg-blue text-light" width="15%">Mobile</th>
                <th className="bg-blue text-light" width="7%">Profile</th>
                <th className="bg-blue text-light" width="7%">Status</th>
                <th className="bg-blue text-light" width="10%">Edit</th>
                {/* <th className="bg-blue text-light" width="5%">Delete</th> */}
              </tr>
            </thead>

            {/* Display all the users */}
            {this.renderUsersList()}

          </table>
          
          {/* Pagination container */}
          {pagination_display}

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
    base_url: state.admin.base_url,
    users : state.user.users,
    total_count : state.paginate.total_count,
    page_count : state.paginate.page_count,
    page_size : state.paginate.page_size,
  }
};

const mapActionsRoProps = {
  onGetUsers : getAllUsers,
  onDeleteUser : deleteUser,
  onLoadingTrue : onLoadingTrue,
  onLoadingFalse : onLoadingFalse,
  onDisplayMessage : onDisplayMessage,
  onDisplayError : onDisplayError,
};

export default connect(mapStatesToProps, mapActionsRoProps) (Users);