import { GET_ADMISSIONS_SUCCESS, GET_ADMISSION_SUCCESS, GET_PAYMENTS_SUCCESS } from '../actions/Admissions';

const initialState = {
    admissions: [],
    admission: {},
    payments: {},
}

export default function Admission_reducer (state = initialState, { type, payload } ) {
    
    switch (type)
    {
        case GET_ADMISSION_SUCCESS:
            return Object.assign({}, state, { admission : payload.admission });

        case GET_ADMISSIONS_SUCCESS:
            return Object.assign({}, state, { admissions : payload.admissions });

        case GET_PAYMENTS_SUCCESS:
            return Object.assign({}, state, { payments : payload.payments });

        default :
            return state;
    }
}
