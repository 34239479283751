import React from 'react';
import './WrittenExam.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getWrittenExamDetail, updateWrittenExam } from '../../actions/WrittenExam';

import swal from 'sweetalert';

class EditWrittenExam extends React.Component {
  constructor(props){
    super(props);
    this.state = { written_exam_id : this.props.match.params.written_exam_id, images : { achiever_image_path : '' } };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {

    this.props.getWrittenExamDetail(this.state.written_exam_id);

  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let writtenExam = this.props.writtenExam;
    let images = this.state.images;
    writtenExam[name] = value;

    if(e.target.name === 'achiever_image_path'){
      images[name] = URL.createObjectURL(e.target.files[0]);
      writtenExam[name] = '';
    }

    this.setState({ writtenExam : writtenExam, images : images });
  }

  onUpdateGallerySubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this achiever?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_achiever_form'));

        this.props.updateWrittenExam(this.state.written_exam_id, formData);
      }
    });
  }

  render() {

    const { writtenExam, base_url } = this.props;


    const { images } = this.state;
    let image_preview = '';

    if(writtenExam && writtenExam.evaluated_paper){
      image_preview = <img src={base_url + writtenExam.evaluated_paper} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(writtenExam.evaluated_paper){
      image_preview = <img src={writtenExam.evaluated_paper} className="img-fluid img-thumbnail" alt=""/>;
    }

    console.log("hhhh", writtenExam);

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/writtenExam">Written Exam</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Submission</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Written Exam Evalution </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              {/*<Link to="/gallery/add_gallery"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewAchiever}>Add <i className="ml-1 fa fa-plus"></i></button></Link> */}
            </div>
          </div>
          
          {/*  Edit achiever form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
              <br/>

              <form id="edit_achiever_form" onSubmit={this.onUpdateGallerySubmit} autoComplete="off">

          

                <div className="form-group">
                  <label className="small_text">Obtained Marks * : </label>
                  <input type="text" name="obtained_marks" placeholder="Obtained marks *" value={writtenExam.obtained_marks ? writtenExam.obtained_marks : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>


                <div className="row form-group">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <label className="small_text">Evaluated Paper * : </label>
                    <input type="file" name="evaluated_paper" onChange={this.onChangeHandler} className="form-control"/>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                    {image_preview}
                  </div>
                </div>
      
      
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update</button>
                </div>


              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    base_url : state.admin.base_url,
    writtenExam: state.writtenExam.writtenExam,

  }
};

const mapActionsRoProps = {
  getWrittenExamDetail: getWrittenExamDetail,
  updateWrittenExam: updateWrittenExam,
};

export default connect(mapStatesToProps, mapActionsRoProps) (EditWrittenExam);
