import React from 'react';
import './Notifications.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendNotifications, onDisplayError } from '../../actions/Login';
import swal from 'sweetalert';

class Notifications extends React.Component {

	constructor (props) {
		super(props);
		this.state = {message: ''};
	}

	onSubmitNotification = (e) => {
		e.preventDefault();

		swal({
			title: "Are you sure?",
			text: "To send this notification",
			buttons: ['Cancel', 'Send'],
		})
		.then(willDelete => {
			if (willDelete) {
				const formData = new FormData(document.getElementById('send_notification_form'));
				this.props.onSendNotifications(formData);
			}
		});
	}

  	render() {
		const {message} = this.state;

    	return (
			<div className="row">
				<div className="col">
		
					{/* Breadcrumbs and add button container */}
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<nav area-label="breadcrumb">
								<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Notifications</li>
								</ol>
							</nav>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<h4 className="text-center page_header"> NOTIFICATIONS </h4>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
						</div>
					</div>

					{/* Edit admin profile */}
					<div className="row add_container form_container">
						<div className="col"></div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 card p-3">
							<h5 className="text-center page_header text-uppercase mt-2">SEND NOTIFICATION</h5>

							<form id="send_notification_form" onSubmit={this.onSubmitNotification} autoComplete="off">
								<div className="form-group">
									<label className="small_text"> Users * : </label>
									<select name="notification_type" className="form-control" required>
										<option value=""> Select Users </option>
										<option value="1"> All Users (Including students) </option>
										<option value="2"> Only Students</option>
									</select>
								</div>
								<div className="form-group">
									<label className="small_text"> Title* : </label>
									<input type="text" name="notification_header" placeholder="Title *" className="form-control" required/>
								</div>

								<div className="form-group">
									<label className="small_text"> Message * : </label>
									<textarea rows={5} name="notification_message" placeholder="Message *" className="form-control" onChange={(e) => this.setState({message: e.target.value})} required/>
									<span className="small_text">{'Total charcters: ' + ('0' + message.length).slice(-2) + ' (160 charcters limited for SMS)'}</span>
								</div>
									
								<div className="form-group text-center">
									<button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''}> SEND </button>
								</div>
							</form>
						</div>
						<div className="col"></div>
					</div>

				</div>
			</div>
		);
  	}

}

const mapStatesToProps = (state) => {
	return {
		isLoading : state.admin.isLoading,
	}
}

const mapActionsToProps = {
	onSendNotifications : sendNotifications,
	onDisplayErroMessage : onDisplayError
}

export default connect(mapStatesToProps, mapActionsToProps) (Notifications);
