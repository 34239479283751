import { GET_HOSTELS_SUCCESS, GET_HOSTEL_SUCCESS } from '../actions/Hostels';

const initialState = {
    hostels : [],
    hostel : {},
}

export default function Hostel_reducer (state = initialState, { type, payload } ) {
    switch (type)
    {
        case GET_HOSTEL_SUCCESS :
            return Object.assign({}, state, { hostel : payload.hostel });

        case GET_HOSTELS_SUCCESS :
            return Object.assign({}, state, { hostels : payload.hostels });

        default :
            return state;
    }

}
