import React from 'react';
import './Books.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBookDetails, updateBook } from '../../actions/Books';
import swal from 'sweetalert';

class Books extends React.Component {
  constructor(props){
    super(props);
    this.state = { book_id : this.props.match.params.book_id, images : { book_image_path : '' } };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.props.onGetBookDetails(this.state.book_id);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let book = this.props.book;
    let images = this.state.images;
    book[name] = value;

    if(e.target.name === 'book_image_path'){
      images[name] = URL.createObjectURL(e.target.files[0]);
      book[name] = '';
    }

    this.setState({ book : book, images : images });
  }

  onUpdateBookSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To update this book?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_book_form'));
        this.props.onUpdateBook(this.state.book_id, formData);
      }
    });
  }

  render() {

    const { book, base_url } = this.props;
    const { images } = this.state;
    let image_preview = '';

    if(book && book.book_image_path){
      image_preview = <img src={base_url + book.book_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }
    else if(images.book_image_path){
      image_preview = <img src={images.book_image_path} className="img-fluid img-thumbnail" alt=""/>;
    }

    return (
      <div className="row">
        <div className="col">
           
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/books">Books</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Book</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Book </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/books/add_book"><button type="button" className="btn btn-primary btn-sm" onClick={this.addNewBook}>Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/*  Edit book form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-12 card p-3">
              <br/>

              <form id="edit_book_form" onSubmit={this.onUpdateBookSubmit} autoComplete="off">

              <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Book Name * : </label>
                      <input type="text" name="book_name" placeholder="Book Name *" className="form-control" value={book.book_name ? book.book_name : ''} onChange={this.onChangeHandler} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Book Type * : </label>
                      <select name="book_type" className="form-control" value={book.book_type ? book.book_type : '0'} onChange={this.onChangeHandler} required>
                        <option value="0"> E-Book </option>
                        <option value="1"> Publications </option>
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                      <label className="small_text">Book Image : </label>
                        <input type="file" name="book_image_path" onChange={this.onChangeHandler} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                          {image_preview}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Payment Type * : </label>
                      <select name="payment_type" value={book.payment_type ? book.payment_type : '0'} onChange={this.onChangeHandler} className="form-control" required>
                        <option value="0"> Free </option>
                        <option value="1"> Paid </option>
                      </select>
                    </div>

                    <div className="form-group">
                      {
                        book.payment_type == "1" ? 
                          <div>
                            <label className="small_text">Price in rupees * : </label>
                            <input type="number" name="book_price" className="form-control" placeholder="Book Price in rupees" value={book.book_price ? book.book_price : ''} onChange={this.onChangeHandler} required />
                          </div>
                        : <input type="hidden" name="book_price" className="form-control" value={0} placeholder="Book Price in rupees" />
                      }
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label className="small_text">Upload Book : </label>
                      <input type="file" name="book_file_path" placeholder="Book Video Path *"  className="form-control" />
                    </div>

                    <div className="form-group">
                      <label className="small_text">Description : </label>
                      <textarea rows={5} name="book_discription" placeholder="Book Description *" className="form-control" value={book.book_discription ? book.book_discription : ''} onChange={this.onChangeHandler}/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Status * : </label>
                      <select name="status" className="form-control" value={book.status ? book.status : '1'} onChange={this.onChangeHandler} required>
                        <option value="1"> Active </option>
                        <option value="0"> Inactive </option>
                      </select>
                    </div>
                  </div>
                </div>
      
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Update Book </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }

}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading, 
    book : state.book.book,
    base_url : state.admin.base_url
  }
};

const mapActionsRoProps = {
  onGetBookDetails : getBookDetails,
  onUpdateBook : updateBook
};

export default connect(mapStatesToProps, mapActionsRoProps) (Books);
