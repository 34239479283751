import React from 'react';
import './Batches.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewBatch } from '../../actions/Batches';
import {getCoursesWithBatches} from '../../actions/Courses';
import {getActiveFaculties} from '../../actions/Faculties';
import swal from 'sweetalert';

class Batches extends React.Component {
    constructor(props){
        super(props);
        this.state = { batchType: 0, images : { batch_image_path : ''}, selectedFaculties: [], totalFees: 0};
        this.onAddBatchSubmit = this.onAddBatchSubmit.bind(this);
    }

    componentWillMount() {
        this.props.onGetActiveFaculties();
    }

    onChangeHandler = (value) => {
        if(value !== '') {
            this.props.onGetCoursesWithBatches(value);
        }
    }

    handleFeesChange = () => {
        var admissionFees = document.getElementById('admission_fees').value;
        var tutionFees = document.getElementById('tution_fees').value;
        var booksFees = document.getElementById('books_fees').value;
        var hostelFees = document.getElementById('hostel_fees').value;
        var mealFees = document.getElementById('meal_fees').value;
        var transportFees = document.getElementById('transportation_fees').value;
        var onlineFees = document.getElementById('online_fees').value;
        var otherFees = document.getElementById('other_fees').value;

        var totalFees = ((admissionFees ? parseInt(admissionFees) : 0) + (tutionFees ? parseInt(tutionFees) : 0) + (booksFees ? parseInt(booksFees) : 0) + (hostelFees ? parseInt(hostelFees) : 0) + (mealFees ? parseInt(mealFees) : 0) + (transportFees ? parseInt(transportFees) : 0) + (onlineFees ? parseInt(onlineFees) : 0) + (otherFees ? parseInt(otherFees) : 0));

        this.setState({totalFees: totalFees});
    }

    handleTotalFeesChange = (totalFees) => {
        this.setState({totalFees: totalFees});
    }

    handleFacultyChange = (e, faculty_id) => {
        let {selectedFaculties} = this.state;

        if(selectedFaculties.includes(faculty_id)){
            selectedFaculties.splice(selectedFaculties.indexOf(faculty_id), 1);
        } else {
            selectedFaculties.push(faculty_id);
        }

        this.setState({ selectedFaculties : selectedFaculties});    
        console.log('Selected values', selectedFaculties);
    }

    onAddBatchSubmit = (e) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "To add this new batch?",
            buttons: ['Cancel', 'Add'],
        })
        .then(willDelete => {
            if (willDelete) {
                var formData = new FormData(document.getElementById('add_batch_form'));
                formData.append('faculty_details', JSON.stringify(this.state.selectedFaculties));
                this.props.onAddNewBatch(formData);
            }
        });
    }

    render() {

        const { images, batchType, selectedFaculties, totalFees } = this.state;
        const {courses, faculties} = this.props;
        let image_preview = '';

        if(images.batch_image_path){
            image_preview = <img src={images.batch_image_path} className="img-fluid img-thumbnail" alt=""/>;
        }

        return (
            <div className="row">
                <div className="col">
                
                    {/* Breadcrumbs and add button container */}
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <nav area-label="breadcrumb">
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                                <li className="breadcrumb-item"><Link to="/batches">Batches</Link> </li>
                                <li className="breadcrumb-item active" area-current="page">Add Batch</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <h4 className="text-center page_header"> Add Batch </h4>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                            {/*  */}
                        </div>
                    </div>
                    
                    {/*  Add new batch form  */}
                    <div className="row add_container">
                        <div className="col"></div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 card p-3">

                            <form id="add_batch_form" onSubmit={this.onAddBatchSubmit} autoComplete="off">
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label className="small_text"> Batch Name * : </label>
                                            <input type="text" name="batch_name" placeholder="Batch Name *" className="form-control" required/>
                                        </div>
                                    
                                        <div className="form-group">
                                            <label className="small_text">Batch Type * : </label>
                                            <select name="batch_type" className="form-control" onChange={(e) => this.onChangeHandler(e.target.value)} required>
                                                <option value=""> Select Batch Type </option>
                                                <option value="1"> Online Batch </option>
                                                <option value="0"> Offline Batch </option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text"> Course * : </label>
                                            <select name="course_id" className="form-control" required>
                                                <option value="0">Select Course </option>
                                                {
                                                courses && courses.map(course => (
                                                    <option key={course.id} value={course.id}>{course.course_name} </option>
                                                ))
                                                }
                                            </select>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col">
                                                <label className="small_text"> Start Date * : </label>
                                                <input type="date" name="batch_start_date" placeholder="Start Date *" className="form-control" required />
                                            </div>
                                            <div className="col">
                                                <label className="small_text"> Start Time * : </label>
                                                <input type="time" name="batch_start_time" placeholder="Start Time *" className="form-control" required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="row form-group">
                                            <div className="col">
                                                <label className="small_text"> Duration  * : </label>
                                                <input type="text" name="batch_duration" placeholder="Batch Duration *" className="form-control" required />
                                            </div>
                                            <div className="col">
                                                <label className="small_text"> Status * : </label>
                                                <select name="status" className="form-control" required>
                                                    <option value="1"> Active </option>
                                                    <option value="0"> Inactive </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="small_text"> Batch Details : </label>
                                            <textarea rows={8} name="batch_details" placeholder="Batch Details" className="form-control"/>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <h5 className="text-center text-blue">SELECT FACULTIES</h5>
                                <div className="row form-group mt-3">
                                {
                                    faculties && faculties.map(faculty => (
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" key={faculty.id}>
                                            <input type="checkbox" checked={selectedFaculties.includes(faculty.id)} value={faculty.id} onChange={(e) => this.handleFacultyChange(e, faculty.id)} />
                                            <label className="medium_text text-blue ml-2">{faculty.faculty_name}</label><br />
                                        </div>
                                    ))
                                }
                                </div>

                                <hr />
                                <h5 className="text-center text-blue">FEES DETAILS</h5>
                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <label className="small_text"> Admission Fees : </label>
                                        <input type="number" name="admission_fees" id="admission_fees" placeholder="Admission Fees" defaultValue={0} onChange={() => this.handleFeesChange()} className="form-control" />
                                    </div>
                                    <div className="col">
                                        <label className="small_text"> Tution Fees : </label>
                                        <input type="number" name="tution_fees" id="tution_fees" placeholder="Tuition Fees" defaultValue={0} onChange={() => this.handleFeesChange()} className="form-control" />
                                    </div>
                                    <div className="col">
                                        <label className="small_text"> Books Fees : </label>
                                        <input type="number" name="books_fees" id="books_fees" placeholder="Books Fees" defaultValue={0} onChange={() => this.handleFeesChange()} className="form-control" />
                                    </div>
                                    <div className="col">
                                        <label className="small_text"> Hostel Fees : </label>
                                        <input type="number" name="hostel_fees" id="hostel_fees" placeholder="Hostel Fees" defaultValue={0}onChange={() => this.handleFeesChange()} className="form-control" />
                                    </div>
                                </div>
                            
                                <div className="row form-group">
                                    <div className="col">
                                        <label className="small_text"> Meal Fees : </label>
                                        <input type="number" name="meal_fees" id="meal_fees" placeholder="Meals Fees" defaultValue={0} onChange={() => this.handleFeesChange()} className="form-control" />
                                    </div>
                                    <div className="col">
                                        <label className="small_text"> Transportation Fees : </label>
                                        <input type="number" name="transportation_fees" id="transportation_fees" defaultValue={0} placeholder="Transportation Fees" onChange={() => this.handleFeesChange()} className="form-control" />
                                    </div>
                                    <div className="col">
                                        <label className="small_text"> Online Fees : </label>
                                        <input type="number" name="online_fees" id="online_fees" placeholder="Online Fees" defaultValue={0} onChange={() => this.handleFeesChange()} className="form-control" />
                                    </div>
                                    <div className="col">
                                        <label className="small_text"> Other Fees : </label>
                                        <input type="number" name="other_fees" id="other_fees" placeholder="Other Fees" defaultValue={0} onChange={() => this.handleFeesChange()} className="form-control" />
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col">
                                        <label className="small_text"> Total Fees : </label>
                                        <input type="number" name="total_fees" placeholder="Total Fees" className="form-control" value={totalFees} onChange={(e) => this.handleTotalFeesChange(e.target.value)} required />
                                    </div>
                                </div>
                            
                                <div className="form-group text-center mt-1">
                                    <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Batch </button>
                                </div>
                            </form>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
  return {
    isLoading: state.admin.isLoading,
    courses: state.course.courses,
    faculties: state.faculty.faculties
  }
};

const mapActionsRoProps = {
  onAddNewBatch: addNewBatch,
  onGetActiveFaculties: getActiveFaculties,
  onGetCoursesWithBatches: getCoursesWithBatches,
};

export default connect(mapStatesToProps, mapActionsRoProps) (Batches);
