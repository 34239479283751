import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewRollingImage } from '../../actions/RollingImages';
import swal from 'sweetalert';

class AddImage extends React.Component {
  constructor(props){
    super(props);
    this.state = { fileType: '0', images : { file_path : ''} };
  }

  onAddRollingImageSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "To add this new rolling image?",
      buttons: ['Cancel', 'Add'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_rolling_image_form'));
        this.props.onAddNewRollingImage(formData);
      }
    });
  }

  handleFileTypeChange = (e) => {
    this.setState({fileType: e.target.value});
  }

  handleImageChange = (e) => {
    const { name } = e.target;
    let images = this.state.images;

    if (name === 'file_path') {
      images['file_path'] = URL.createObjectURL(e.target.files[0]);
    }

    this.setState({ images : images });
  }

  render() {

    const {images, fileType} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/rolling_images">RollingImages</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add RollingImage</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add RollingImage </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
            </div>
          </div>
          
          {/*  Add new rollingImage form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12 card p-3">
              <br/>

              <form id="add_rolling_image_form" onSubmit={this.onAddRollingImageSubmit} autoComplete="off">
                <div className="form-group">
                  <select name="file_type" value={fileType ? fileType : "0"} onChange={(e) => this.handleFileTypeChange(e)} className="form-control">
                    <option value="0"> Image </option>
                    <option value="1"> Video </option>
                  </select>
                </div>

                {
                  fileType == "0" ? 
                    <div className="row form-group">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                        <label className="small_text">Image : </label>
                        <input type="file" name="file_path" placeholder="File" onChange={(e) => this.handleImageChange(e)} className="form-control"/>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                        {images.file_path !== '' ? <img src={images.file_path} className="img-fluid img-thumbnail" alt=""/> : null}
                      </div>
                    </div>
                : <div className="form-group">
                    <input type="text" name="file_path" placeholder="Youtube Video ID" className="form-control"/>
                  </div>
                }
                
                <div className="form-group">
                  <input type="number" name="order_number" placeholder="Order Number" className="form-control" required/>
                </div>

                <div className="form-group">
                  <select name="status" className="form-control">
                    <option value="1"> Active </option>
                    <option value="0"> Inactive </option>
                  </select>
                </div>
              
                <div className="form-group text-center mt-1">
                  <button type="submit" className="btn btn-success" disabled={this.props.isLoading ? 'disabled' : ''} > Add Rolling Image </button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
  return {
    isLoading : state.admin.isLoading,
  }
};

const mapActionsRoProps = {
  onAddNewRollingImage : addNewRollingImage,
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddImage);
